<template>

    <button :class="parentClass" class="icon-container no-btn" @click.prevent="showMerklisteModal" :title="$t('controls.remember')">
        <span class="material-icons-outlined bookmark-icon" v-if="!isInCollectionClass">
            bookmark_border           
        </span>
        <span class="material-icons-outlined bookmark-icon active" v-if="isInCollectionClass">
            bookmark        
        </span>
    </button>

</template>
<script>
  import { bus } from '@/main'

  export default {
    name: "bookmarkButton",
    props: {
        parentClass: {
            type: String,
            required: false,
            default: "card-body-icon"
        },
        content: {
            type: Object,
            required: true
        },
    },
    computed: {
        isInCollection(){
            if(this.isLoggedIn && this.isContentInCollection()){
                return true;
            }
            return false;
        },
        isInCollectionClass(){
            if(this.isInCollection == true){
                return true;
            }
            return false;
        },
        collections(){
          return this.$store.getters.getUserCollections;
      },
      isLoggedIn(){
        if(this.$store.getters.getToken){
            return true;
        }
        return false;
    },
    getIcon(){
        if(this.isInCollection){
            return "/assets/logo/WOLFSBURGER LUPE-Icon.png";
        }
        return "/assets/logo/WOLFSBURGER LUPE-Icon.png";
    }
},
methods: {
  showMerklisteModal () {
    bus.$emit('show-merkliste-modal', {show: true, content: this.content});
},
isContentInCollection(){
    if(this.collections!=null){
        for (var prop in this.collections) {
            var collectionGroup = this.collections[prop];
            for(var i=0; i<collectionGroup.length; i++){
                var currentCollection = collectionGroup[i];
                if(Object.prototype.hasOwnProperty.call(currentCollection, 'contents') && currentCollection.contents!=null){
                    if(currentCollection!=null && currentCollection.contents.length>0){
                        for(var j=0; j<currentCollection.contents.length; j++){
                            if(currentCollection.contents[j].id == this.content.id){
                                return true;
                            }
                        }
                    }
                }
            }
        }
    }
    return false;
},
}
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .active{
    border-color: #E5321B!important;
    color: #157FAC!important;
}

.btn-card .icon{
    margin-top: 5px;
    text-align: center;
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 36px;
    text-align: center;
    font-size: 24px;
    color: #000000;
}

.btn-card .icon img {
    color: #FFFFFF;
    height: 21px;
}

.card-body-icon {
    align-items: center;
    justify-content: center;
    display: flex;
    float: right;
    height: 48px;
    width: 48px;
    position: absolute;
    right: 50px;
    bottom: 2px;
    padding: 5px;
}

.card-body-icon .icon {
    text-align: center;
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 36px;
    text-align: center;
    font-size: 24px;
    color: #000000;
}


.icon img{
    height: 21px;
}

.bookmark-icon {
    color: $dark-color;
}


.icon-container:hover {
    .bookmark-icon {
        color: $primary;
    }
}

body[dir="rtl"] {
    .card-body-icon {
        right: auto;
        left: 50px;
    }
    }

</style>
